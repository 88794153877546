// App.js

import React, { useEffect, useCallback, useState } from 'react';
import Particles from 'react-particles';
import { loadFull } from 'tsparticles';
import particlesOptions from './config/particles-config';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function App() {
  const [isDarkMode, setDarkMode] = useState(false);

  const toggleDarkMode = () => {
    setDarkMode(!isDarkMode);
  };

  const particlesInit = useCallback((main) => {
    loadFull(main);
  }, []);

  const getParticleOptions = () => {
    const baseOptions = particlesOptions;
    return {
      particles: {
        ...baseOptions.particles,
        color: {
          value: isDarkMode ? '#ffffff' : '#000000',
        },
      },
    };
  };

  useEffect(() => {
    const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;

    // Set the initial mode based on user's device settings
    setDarkMode(prefersDarkMode);
  }, []);

  return (
    <div className={`App ${isDarkMode ? 'dark-mode' : 'light-mode'}`}>
      <Particles options={getParticleOptions()} init={particlesInit} />

      <div className="container-fluid">
        <div className="row align-items-center justify-content-center">
          <div className="col-md-10 text-center">
            <h1 className="title">Gaurav Saini</h1>
            <p>
              Welcome to my personal website! I'm Gaurav Saini, a fourth/final year student at <a href="https://www.senecapolytechnic.ca/home.html" target="_blank" rel="noopener noreferrer">
              <u><b>Seneca Polytechnic</b></u>&nbsp;
              </a>
              pursuing a <a href="https://www.senecapolytechnic.ca/programs/fulltime/BSD.html" target="_blank" rel="noopener noreferrer">
              <u><b>Bachelor of Technology in Software Development</b></u>
              </a>, backed by four months of internship work
              experience as a Full Stack Developer.
            </p>
            <p>
              Explore my projects, achievements, and insights by clicking on the red text or by clicking the circles below. You can view my{' '}
              <a href="https://www.linkedin.com/in/gauravsaini30" target="_blank" rel="noopener noreferrer">
                <u><b>LinkedIn</b></u>
              </a>
              , explore my{' '}
              <a href="https://github.com/Gaurav3023" target="_blank" rel="noopener noreferrer">
                <u><b>GitHub</b></u>
              </a>{' '}
              projects, send me an{' '}
              <a href="mailto:gauravsaini5@outlook.com" target="_blank" rel="noopener noreferrer">
                <u><b>Email</b></u>
              </a>
              , and check out my{' '}
              <a href="./Gaurav.pdf" target="_blank" rel="noopener noreferrer">
                <u><b>Resume</b></u>
              </a>
              .
            </p>
            <p>
              Your visit is appreciated, and I eagerly anticipate the opportunity to connect with you.
            </p>
            <p>Thanks for stopping by 🙂!</p>
            <div className="circle-container">
              <a href="https://www.linkedin.com/in/gauravsaini30" target="_blank" className={`circle ${isDarkMode ? 'dark-circle' : 'light-circle'}`} title="LinkedIn">
                <img src={require('./linkedin.png')} alt="LinkedIn" />
              </a>
              <a href="https://github.com/Gaurav3023" target="_blank" className={`circle ${isDarkMode ? 'dark-circle' : 'light-circle'}`} title="Github">
                <img src={require('./github-3.png')} alt="GitHub" />
              </a>
              <a href="mailto:gauravsaini5@outlook.com" target="_blank" className={`circle ${isDarkMode ? 'dark-circle' : 'light-circle'}`} title="Email">
                <img src={require('./email-2.png')} alt="sainigaurav746@gmail.com" />
              </a>
              <a href="./Gaurav.pdf" target="_blank" className={`circle ${isDarkMode ? 'dark-circle' : 'light-circle'}`} title="Resume">
                <img src={require('./acrobat.png')} alt="Resume" />
              </a>
            </div>

            <button className={'btn btn-secondary toggle-button'} onClick={toggleDarkMode}>
              {isDarkMode ? 'Switch to Light Mode 🔁' : 'Switch to Dark Mode 🔁'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
